import React, { useReducer } from 'react'
import CustomerContext from './customerContext'
import CustomerReducer from './customerReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    RESPONSE_STATUS,
    GET_INVOICE_DETAILS,
    RESET_INVOICE_DETAILS,
    SET_INVOICE_LIST,
    RESET_INVOICE_LIST,
    SET_SUBSCRIPTION_LIST,
    SET_CUSTOMER_SOURCE,
    RESET_SUBSCRIPTION_LIST,
    GET_ALL_CUSTOMER,
    SET_NEW_CUSTOMER,
    SET_CUSTOMER_BY_ID,
    RESET_PAGINATION,
    SET_PAGINATION,
    SET_INVOICE_ITEMS,
    SET_TAX_COUPONS,
    SET_LOADING_TRUE,
    SET_LOADING_FALSE,
    CLEAR_RESPONSE,
    SET_RESPONSE,
    GET_ACCOUNT_CUSTOMER,
    SET_CUSTOMIZE_INVOICE,
} from './customerTypes'

const CustomerState = (props) => {
    const initialState = {
        loading: false,
        responseStatus: '',
        invoiceList: null,
        subscriptionList: null,
        customerSourceList: null,
        all_customers: null,
        all_account_customers: null,
        customize_invoice: null,
        newCustomer: {
            customer_id: null,
            customer: null,
        },
        pagination: {
            pageCount: 0,
            previous: true,
            next: false,
        },
        all_invoice_items: null,
        tax_coupons: null,
        invoiceDetails: null,
    }

    const [state, dispatch] = useReducer(CustomerReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const listCustomerInvoice = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'invoiceList', formData, '', 'customers'),
                // apiCall('post', 'getInvoiceList', formData, '', 'customers'),
            ])
            const from = 'invoiceList' //'getInvoiceList'
            if (res.data.status === 'success') {
                dispatch({
                    type: SET_RESPONSE,
                    payload: { status: res.data.status, message: res.data.data.message, from },
                })
                dispatch({
                    type: SET_INVOICE_LIST,
                    payload: res.data.data.responseData,
                })
                dispatch({
                    type: SET_PAGINATION,
                    payload: {
                        pageCount: state.pagination.pageCount,
                        previous: state.pagination.pageCount <= 1 ? true : false,
                        next: !res.data.data.responseData.has_more,
                    },
                })
                if (formData.starting_after) {
                    dispatch({
                        type: SET_PAGINATION,
                        payload: {
                            pageCount: state.pagination.pageCount + 1,
                            previous: false,
                            next: !res.data.data.responseData.has_more,
                        },
                    })
                } else if (formData.ending_before) {
                    dispatch({
                        type: SET_PAGINATION,
                        payload: {
                            pageCount: state.pagination.pageCount - 1,
                            previous: state.pagination.pageCount <= 1 ? true : false,
                            next: false,
                        },
                    })
                }
            }
        } catch (err) {
            resp.commonErrorResponse('listCustomerInvoice')
        }
    }

    const fetchCustomerInvoiceDetails = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'invoiceDetails', formData, '', 'customers'),
            ])
            if (res.data?.status === 'success') {
                dispatch({
                    type: GET_INVOICE_DETAILS,
                    payload: res.data.data.responseData,
                })
            } else if (res.data?.status === 'error') {
                dispatch({
                    type: GET_INVOICE_DETAILS,
                    payload: res.data.data,
                    from: 'fetchCustomerInvoiceDetails',
                })
                resp.commonErrorResponse(res.data, 'fetchCustomerInvoiceDetails')
            }
        } catch (err) {
            resp.commonErrorResponse('fetchCustomerInvoiceDetails')
        }
    }

    const clearInvoiceDetails = async () => {
        dispatch({ type: RESET_INVOICE_DETAILS })
    }

    const listCustomerSubscription = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getSubscriptionsList', formData, '', 'customers'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: SET_SUBSCRIPTION_LIST,
                    payload: res.data.data.responseData,
                })
                dispatch({
                    type: SET_PAGINATION,
                    payload: {
                        pageCount: state.pagination.pageCount,
                        previous: state.pagination.pageCount <= 1 ? true : false,
                        next: !res.data.data.responseData.has_more,
                    },
                })
                if (formData.starting_after) {
                    dispatch({
                        type: SET_PAGINATION,
                        payload: {
                            pageCount: state.pagination.pageCount + 1,
                            previous: false,
                            next: !res.data.data.responseData.has_more,
                        },
                    })
                } else if (formData.ending_before) {
                    dispatch({
                        type: SET_PAGINATION,
                        payload: {
                            pageCount: state.pagination.pageCount - 1,
                            previous: state.pagination.pageCount <= 1 ? true : false,
                            next: false,
                        },
                    })
                }
            }
        } catch (err) {
            resp.commonErrorResponse('listCustomerSubscription')
        }
    }

    const getCustomersSource = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getCustomersSource', formData, '', 'customers'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: SET_CUSTOMER_SOURCE,
                    payload: res.data.data.responseData.data,
                })
            }
        } catch (err) {
            resp.commonErrorResponse('getCustomersSource')
        }
    }

    const createCustomer = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'createCustomer', formData, '', 'customers'),
            ])

            if (res.data.status === 'success') {
                resp.commonResponse(res.data, 'customerAction')
                dispatch({
                    type: SET_NEW_CUSTOMER,
                    payload: res.data.data.responseData.id,
                })
            }
        } catch (err) {
            resp.commonErrorResponse('createCustomer')
        }
    }

    const getAllCustomer = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getCustomersList', formData, '', 'customers'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_CUSTOMER,
                    payload: {
                        records: res.data.data.responseData.records
                            ? res.data.data.responseData.records
                            : [],
                        has_more: res.data.data.responseData.has_more
                            ? res.data.data.responseData.has_more
                            : null,
                    },
                })
            } else {
                resp.commonResponse(res.data, 'getAllCustomer')
            }
        } catch (err) {
            resp.commonErrorResponse('getAllCustomer')
        }
    }

    const getAccountCustomers = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getAccountCustomers', formData, '', 'customers'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ACCOUNT_CUSTOMER,
                    payload: {
                        records: res.data.data.responseData.records
                            ? res.data.data.responseData.records
                            : [],
                        has_more: res.data.data.responseData.has_more
                            ? res.data.data.responseData.has_more
                            : null,
                    },
                })
            } else {
                resp.commonResponse(res.data, 'getAccountCustomers')
            }
        } catch (err) {
            resp.commonErrorResponse('getAccountCustomers')
        }
    }

    const createNewCustomer = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'createNewCustomer', formData, '', 'customers'),
            ])

            const from = 'createNewCustomer'

            if (res.data.status === 'success') {
                dispatch({
                    type: SET_RESPONSE,
                    payload: { status: res.data.status, message: res.data.data.message, from },
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const updateExistingCustomer = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateExistingCustomer', formData, '', 'customers'),
            ])

            const from = 'updateExistingCustomer'

            if (res.data.status === 'success') {
                dispatch({
                    type: SET_RESPONSE,
                    payload: { status: res.data.status, message: res.data.data.message, from },
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const deleteCustomers = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'deleteCustomers', formData, '', 'customers'),
            ])

            const from = 'deleteCustomers'

            if (res.data.status === 'success') {
                dispatch({
                    type: SET_RESPONSE,
                    payload: { status: res.data.status, message: res.data.data.message, from },
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getCustomerById = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getCustomerById', formData, '', 'customers'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: SET_CUSTOMER_BY_ID,
                    payload: res.data.data.responseData,
                })
            }
        } catch (err) {
            resp.commonErrorResponse('getCustomerById')
        }
    }

    const resetInvoiceList = async () => {
        dispatch({ type: RESET_INVOICE_LIST })
    }

    const resetSubscriptionList = async () => {
        dispatch({ type: RESET_SUBSCRIPTION_LIST })
    }

    const resetPage = async () => {
        dispatch({ type: RESET_PAGINATION })
    }

    const listInvoiceItems = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'listInvoiceItems', formData, '', 'customers/invoice'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: SET_INVOICE_ITEMS,
                    payload: res.data.data.responseData,
                })
            }
        } catch (err) {
            resp.commonErrorResponse('listInvoiceItems')
        }
    }

    const createInvoiceItem = async (formData) => {
        try {
            const { customer_id, name, qty, price } = formData
            const data = {
                customer_id,
                product_name: name,
                currency: 'usd',
                unit_amount: price * 100,
                quantity: qty,
            }
            const [res] = await Promise.all([
                apiCall('post', 'createInvoiceItem', data, '', 'customers/invoice'),
            ])
            if (res.data.status === 'success') {
                listInvoiceItems()
            }
        } catch (err) {
            resp.commonErrorResponse('createInvoiceItem')
        }
    }

    const updateInvoiceItem = async (formData) => {
        try {
            const { invoiceitem_id, name, qty, price } = formData
            const data = {
                invoiceitem_id,
                product_name: name,
                currency: 'usd',
                unit_amount: price * 100,
                quantity: qty,
            }
            const [res] = await Promise.all([
                apiCall('post', 'updateInvoiceItem', data, '', 'customers/invoice'),
            ])
            if (res.data.status === 'success') {
                listInvoiceItems()
            }
        } catch (err) {
            resp.commonErrorResponse('updateInvoiceItem')
        }
    }

    const deleteInvoiceItem = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'deleteInvoiceItem', formData, '', 'customers/invoice'),
            ])
            if (res.data.status === 'success') {
                listInvoiceItems()
            }
        } catch (err) {
            resp.commonErrorResponse('deleteInvoiceItem')
        }
    }

    const sendInvoice = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'sendInvoice', formData, '', 'customers/invoice'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: SET_RESPONSE,
                    payload: {
                        status: res.data.status,
                        message: res.data.data.message,
                        from: 'sendInvoice',
                    },
                })
                listInvoiceItems()
            }
            if (res.data.status === 'error') {
                dispatch({
                    type: SET_RESPONSE,
                    payload: {
                        status: res.data.status,
                        message: res?.data?.data?.message
                            ? res.data.data.message
                            : res?.data?.message?.message
                            ? res.data.message.message
                            : null,
                    },
                })
            }
        } catch (err) {
            resp.commonErrorResponse('sendInvoice')
        }
    }

    const draftInvoice = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'draftInvoice', formData, '', 'customers/invoice'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: SET_RESPONSE,
                    payload: {
                        status: res.data.status,
                        message: res.data.data.message,
                        from: 'draftInvoice',
                    },
                })
                listInvoiceItems()
            }
            if (res.data.status === 'error') {
                dispatch({
                    type: SET_RESPONSE,
                    payload: {
                        status: res.data.status,
                        message: res?.data?.data?.message
                            ? res.data.data.message
                            : res?.data?.message?.message
                            ? res.data.message.message
                            : null,
                    },
                })
            }
        } catch (err) {
            resp.commonErrorResponse('draftInvoice')
        }
    }

    const voidInvoice = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'voidInvoice', formData, '', 'customers/invoice'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: SET_RESPONSE,
                    payload: { status: res.data.status, message: res.data.data.message },
                })
                listInvoiceItems()
            }
            if (res.data.status === 'error') {
                dispatch({
                    type: SET_RESPONSE,
                    payload: { status: res.data.status, message: res.data.data.message },
                })
            }
        } catch (err) {
            resp.commonErrorResponse('voidInvoice')
        }
    }

    const sendSavedInvoice = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'sendSavedInvoice', formData, '', 'customers/invoice'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: SET_RESPONSE,
                    payload: {
                        status: res.data.status,
                        message: res.data.data.message,
                        from: 'sendSavedInvoice',
                    },
                })
                listInvoiceItems()
            }
            if (res.data.status === 'error') {
                dispatch({
                    type: SET_RESPONSE,
                    payload: { status: res.data.status, message: res.data.data.message },
                })
            }
        } catch (err) {
            resp.commonErrorResponse('sendSavedInvoice')
        }
    }

    const resendInvoice = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'resendInvoice', formData, '', 'customers/invoice'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: SET_RESPONSE,
                    payload: {
                        status: res.data.status,
                        message: res.data.data.message,
                        from: 'resendInvoice',
                    },
                })
                listInvoiceItems()
            }
            if (res.data.status === 'error') {
                dispatch({
                    type: SET_RESPONSE,
                    payload: { status: res.data.status, message: res.data.data.message },
                })
            }
        } catch (err) {
            resp.commonErrorResponse('resendInvoice')
        }
    }

    const listTaxCoupons = async (formdata) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'listTaxCoupons', '', '', 'customers'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: SET_TAX_COUPONS,
                    payload: res.data.data.responseData,
                })
            }
        } catch (err) {
            resp.commonErrorResponse('listTaxCoupons')
        }
    }

    const updateCard = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateSource', formData, '', 'customers'),
            ])
            dispatch({
                type: SET_RESPONSE,
                payload: {
                    status: res.data.status,
                    message: res.data.data.message,
                },
            })
            getCustomersSource({ customer_id: formData.customer_id })
        } catch (err) {
            resp.commonErrorResponse('listTaxCoupons')
        }
    }

    const deleteCard = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'deleteSource', formData, '', 'customers'),
            ])
            dispatch({
                type: SET_RESPONSE,
                payload: {
                    status: res.data.status,
                    message: res.data.data.message,
                },
            })
            getCustomersSource({ customer_id: formData.customer })
        } catch (err) {
            resp.commonErrorResponse('listTaxCoupons')
        }
    }

    const getCustomizeInvoice = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'customizeInvoice', formData, '', 'customers/invoice'),
            ])
            const from = 'customizeInvoice'
            if (res.data.status === 'success') {
                resp.commonResponse(res.data, from)
                dispatch({
                    type: SET_CUSTOMIZE_INVOICE,
                    payload: res.data.data.responseData,
                })
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const saveCustomizedInvoice = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'saveCustomizeInvoice', formData, '', 'customers/invoice'),
            ])
            const from = 'saveCustomizeInvoice'
            if (res.data.status === 'success') {
                dispatch({
                    type: SET_RESPONSE,
                    payload: {
                        status: res.data.status,
                        message: res.data.data.message,
                        from,
                    },
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const changeTemplateStatus = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'changeTemplateStatus', formData, '', 'customers/invoice'),
            ])
            const from = 'changeTemplateStatus'
            if (res.data.status === 'success') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const clearResponse = async () => {
        dispatch({
            type: CLEAR_RESPONSE,
        })
    }

    return (
        <CustomerContext.Provider
            value={{
                responseStatus: state.responseStatus,
                invoiceList: state.invoiceList,
                subscriptionList: state.subscriptionList,
                customerSourceList: state.customerSourceList,
                all_customers: state.all_customers,
                newCustomer: state.newCustomer,
                pagination: state.pagination,
                all_invoice_items: state.all_invoice_items,
                tax_coupons: state.tax_coupons,
                all_account_customers: state.all_account_customers,
                customize_invoice: state.customize_invoice,
                invoiceDetails: state.invoiceDetails,
                listCustomerInvoice,
                getAccountCustomers,
                resetInvoiceList,
                listCustomerSubscription,
                getCustomersSource,
                getAllCustomer,
                createCustomer,
                getCustomerById,
                resetSubscriptionList,
                createNewCustomer,
                updateExistingCustomer,
                deleteCustomers,
                resetPage,
                listInvoiceItems,
                listTaxCoupons,
                createInvoiceItem,
                updateInvoiceItem,
                deleteInvoiceItem,
                sendInvoice,
                draftInvoice,
                voidInvoice,
                sendSavedInvoice,
                resendInvoice,
                updateCard,
                deleteCard,
                getCustomizeInvoice,
                saveCustomizedInvoice,
                changeTemplateStatus,
                clearResponse,
                fetchCustomerInvoiceDetails,
                clearInvoiceDetails,
            }}
        >
            {props.children}
        </CustomerContext.Provider>
    )
}

export default CustomerState
